import PageWrapper from "../components/Layout/PageWrapper";
import Grid from "../components/Layout/Grid";
import Calendar from "../components/GoogleCalendar";
import { useNavigate } from "react-router-dom";
import CFCApi from "../apis";
import moment from "moment";

export default function AgendaCalendar() {
  const navigate = useNavigate();

  const handleAgendaData = async ({timeMin, timeMax}: any) => {
    return await CFCApi.get(
      `/users/agenda?minDate=${moment(timeMin)
        .format("YYYY-MM-DD")}&maxDate=${moment( timeMax)
        .format("YYYY-MM-DD")}`
    );
  }

  const clickCallback = (date: any) => {
    navigate("/agenda/add-rdv", {
      state: { date },
    });
  }

  return (
    <PageWrapper>
      <h2>Prise de rendez-vous</h2>
      <Grid container>
        <Grid item md={12} xs={12}>
          <div className="calendar-wrapper">
            {/* @ts-ignore */}
            <Calendar  
              handleAgendaData={handleAgendaData} 
              clickCallback={clickCallback} 
              showFooter={false}
              />
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

