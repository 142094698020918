import { Container } from "@mui/material";
import Button from "../components/Button";

const ErrorMessage = () => {
  return (
    <Container maxWidth="md">
      <div className="error-message">
        <h1>Erreur - Quelque chose a mal tourné</h1>
        <p>Désolé, une erreur s'est produite. Veuillez réessayer plus tard.</p>
        <Button
          text="Retour"
          size="sm"
          to={() => (window.location.href = "/")}
        />
      </div>
    </Container>
  );
};

export default ErrorMessage;
