import { useSelector } from "react-redux";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Loading from "./components/Loading";
import Modal from "./components/Modal";
import JWTDecode from "./helpers/JWTDecode";
import Login from "./routes/Login";
import ResetPassword from "./routes/ResetPassword";
import NotFoundPage from "./routes/NotFoundPage";
import UserRoutes from "./routes/UserRoutes";
import ErrorMessage from "./routes/ErrorPage";

function App() {
  const user = JWTDecode();

  const stateApp = useSelector((state: any) => state.app);
  const isLoading = stateApp?.loading;
  const isModel = stateApp?.model;

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <div className="App">
          {isModel && <Modal {...isModel} />}
          {isLoading && <Loading />}
          {user ? (
            <UserRoutes />
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          )}
        </div>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
