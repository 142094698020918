import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="md">
      <div className="error-message">
        <h1>404 - Page Non Trouvée</h1>
        <p>Désolé, la page que vous recherchez n'existe pas.</p>
        <Button text="Retour" size="sm" to={() => navigate("/")} />
      </div>
    </Container>
  );
};

export default ErrorPage;
