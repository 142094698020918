import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import CFCApi from "../apis";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator, { Field } from "../components/Form";
import Button from "../components/Button";
import decode from "../helpers/JWTDecode";
import Cookie from "js-cookie";
import { setUser } from "../state/slices/userSlice";
import useTrigger from "../hooks/useTrigger";

export default function Profile() {
  const dispatch = useDispatch();
  const { triggerModel, triggerLoading } = useTrigger();

  const [isUpdatePassword, setIsUpdatePassword] = useState<boolean>(false)
  const [passwordVerification, setPasswordVerification] = useState<any>(false)
  const [profileFields, setProfileFields] = useState<any>(null);

  const passwrodFields:Field[] = [
      {
        name: "oldPassword",
        type: "password",
        label: "Mot de passe actuel",
        required: true,
      },
      {
        name: "password",
        type: "password",
        label: "Mot de passe",
        required: true,
        strictPassword: true,
      },
      {
        name: "confirmPassword",
        type: "password",
        label: "Confirmer le mot de passe",
        required: true,
      }
  ]

  interface UserData {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  }

  useEffect(() => {
    (async () => {
      triggerLoading(true);
      
      const userToken: UserData = decode(Cookie.get("token")) as any;
      const userResponse = await CFCApi.get(`/users/${userToken.id}`);
      dispatch(setUser(userResponse));
      setProfileFields([
        {
          name: "firstName",
          type: "text",
          label: "Nom",
          required: true,
          defaultValue: userResponse.firstName || null,
          isEditable: true,
        },
        {
          name: "lastName",
          type: "text",
          label: "Prénom",
          required: true,
          defaultValue: userResponse.lastName || null,
          isEditable: true,
        },
        {
          name: "email",
          type: "email",
          label: "Email",
          defaultValue: userResponse.email || null,
          required: true,
          isReadOnly: true,
        },
      ]);
      triggerLoading(false)
    })();
  }, []);

  const handleProfileSubmit = ( values: {
    [key: string]: string | string[];
  }) => {
    setPasswordVerification(values)
  };



  const handlePasswordSubmit = async (values: {
    [key: string]: string | string[];
  }) => {

      try {
      triggerLoading(true)
        const response = await CFCApi.patch("/users/profile", values);
        if (response) {
          dispatch(setUser(response));
          triggerModel("success", "Mise à jour réussie")
          setIsUpdatePassword(false);
        } else {
          throw new Error("An unexpected error occurred");
        }
      } catch (error: any) {
        triggerModel("error", error.message)
    } finally {
      triggerLoading(false)
    }
  };




  const handlePasswordVerificationSubmit = async (values: any) => {
    try {
      delete values.confirmPassword;
      triggerLoading(true)
      const validPassword = await CFCApi.post("/login", {
        email: profileFields[2].defaultValue,
        password: values.password,
      });

      if(!validPassword.token) throw new Error('Passowrd is not valid')

      const response = await CFCApi.patch("/users/profile", passwordVerification);

      const { firstName, lastName, email } = response;

      window.localStorage.setItem(
        "user",
        JSON.stringify({ firstName, lastName, email })
      );

      if (response) {
        triggerModel("success", "Mise à jour réussie")
        profileFields[0].defaultValue = firstName;
        profileFields[1].defaultValue = lastName;
        profileFields[2].defaultValue = email;
        setProfileFields(profileFields)
        setPasswordVerification(false);
      } else {
        throw new Error("An unexpected error occurred");
      }
    } catch (error: any) {
        triggerModel("error", error.message)
    } finally {
      triggerLoading(false)
    }
  }

  return (
    <PageWrapper>
      <div className="login-wrapper">
        <h1>{isUpdatePassword ? 'Modifier mot de passe' : 'Modifier profil'}</h1>
        {profileFields && !isUpdatePassword && !passwordVerification && (
          <FormGenerator
            fields={profileFields}
            submitButtonText="Continuer"
            onSubmit={handleProfileSubmit}
            widthReturn
          />
        )}
        {
          passwordVerification && (
          <FormGenerator
            fields={[
              {
                name: "password",
                type: "password",
                label: "Mot de passe requis pour la mise à jour",
                required: true,
              }
            ]}
            submitButtonText="Sauvegarder"
            onSubmit={handlePasswordVerificationSubmit}
            widthReturn
            handleReturn={() => setPasswordVerification(null)}
          />

          )
        }
        {isUpdatePassword &&  (
          <FormGenerator
            fields={passwrodFields}
            submitButtonText="Sauvegarder"
            onSubmit={handlePasswordSubmit}
          />
        )}
        { !passwordVerification && (
          <Fragment>
              <br/>
              <hr/>
              <br/>
              <Button
                text={!isUpdatePassword ? 'Modifier mot de passe' : 'Modifier profil'}
                block
                onPress={() => setIsUpdatePassword(!isUpdatePassword)}
              />
          </Fragment>
          )}
      </div>
    </PageWrapper>
  );
}
